/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

// merit-vfe: Import custom error for 400 status code
import { Error400 } from "../exceptions/Error400";
// merit-vfe

import { AddUserPayload, UpdateProfilePayload, UpdateSignaturePayload } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

// merit-vfe: Possible response structure for generated api clients
type Response<T = void> =
  | {
      readonly success: false;
      readonly message: string;
    }
  | (T extends void
      ? {
          readonly success: true;
        }
      : {
          readonly success: true;
          readonly data: T;
        });
// merit-vfe

export class User<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name GetUser
   * @request GET:/api/user/
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  getUser = async (
    params: RequestParams = {},
  ): Promise<
    Response<{
      email: string;
      firstName: string;
      forms: {
        id: string;
        jotformUrl: string;
        name: string;
        operationalPeriod?: {
          meritTemplateIds: string[];
          meritTemplateIdsUsedFor:
            | "Check-in only"
            | "Check-in and Check-out"
            | ("Check-in only" & "Check-in and Check-out");
          title: string;
        };
        orgName: string;
      }[];
      hasSignature: boolean;
      id: string;
      isStaffOfAnyForm: boolean;
      isSuperAdmin: boolean;
      isSupervisorOfAnyForm: boolean;
      lastName: string;
    }>
  > => {
    try {
      const response = await this.request<
        {
          email: string;
          firstName: string;
          forms: {
            id: string;
            jotformUrl: string;
            name: string;
            operationalPeriod?: {
              meritTemplateIds: string[];
              meritTemplateIdsUsedFor:
                | "Check-in only"
                | "Check-in and Check-out"
                | ("Check-in only" & "Check-in and Check-out");
              title: string;
            };
            orgName: string;
          }[];
          hasSignature: boolean;
          id: string;
          isStaffOfAnyForm: boolean;
          isSuperAdmin: boolean;
          isSupervisorOfAnyForm: boolean;
          lastName: string;
        },
        {
          error: string;
        }
      >({
        path: `/api/user/`,
        method: "GET",
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name GetSignatureUrl
   * @request GET:/api/user/signature
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  getSignatureUrl = (params: RequestParams = {}) =>
    this.request<
      {
        signatureUrl: string;
      },
      any
    >({
      path: `/api/user/signature`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * No description
   *
   * @name UpdateSignature
   * @request POST:/api/user/signature
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  updateSignature = async (
    body: UpdateSignaturePayload,
    params: RequestParams = {},
  ): Promise<
    Response<{
      email: string;
      firstName: string;
      forms: {
        id: string;
        jotformUrl: string;
        name: string;
        operationalPeriod?: {
          meritTemplateIds: string[];
          meritTemplateIdsUsedFor:
            | "Check-in only"
            | "Check-in and Check-out"
            | ("Check-in only" & "Check-in and Check-out");
          title: string;
        };
        orgName: string;
      }[];
      hasSignature: boolean;
      id: string;
      isStaffOfAnyForm: boolean;
      isSuperAdmin: boolean;
      isSupervisorOfAnyForm: boolean;
      lastName: string;
    }>
  > => {
    try {
      const response = await this.request<
        {
          email: string;
          firstName: string;
          forms: {
            id: string;
            jotformUrl: string;
            name: string;
            operationalPeriod?: {
              meritTemplateIds: string[];
              meritTemplateIdsUsedFor:
                | "Check-in only"
                | "Check-in and Check-out"
                | ("Check-in only" & "Check-in and Check-out");
              title: string;
            };
            orgName: string;
          }[];
          hasSignature: boolean;
          id: string;
          isStaffOfAnyForm: boolean;
          isSuperAdmin: boolean;
          isSupervisorOfAnyForm: boolean;
          lastName: string;
        },
        {
          error: string;
        }
      >({
        path: `/api/user/signature`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name UpdateProfile
   * @request POST:/api/user/profile
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  updateProfile = async (
    body: UpdateProfilePayload,
    params: RequestParams = {},
  ): Promise<
    Response<{
      email: string;
      firstName: string;
      forms: {
        id: string;
        jotformUrl: string;
        name: string;
        operationalPeriod?: {
          meritTemplateIds: string[];
          meritTemplateIdsUsedFor:
            | "Check-in only"
            | "Check-in and Check-out"
            | ("Check-in only" & "Check-in and Check-out");
          title: string;
        };
        orgName: string;
      }[];
      hasSignature: boolean;
      id: string;
      isStaffOfAnyForm: boolean;
      isSuperAdmin: boolean;
      isSupervisorOfAnyForm: boolean;
      lastName: string;
    }>
  > => {
    try {
      const response = await this.request<
        {
          email: string;
          firstName: string;
          forms: {
            id: string;
            jotformUrl: string;
            name: string;
            operationalPeriod?: {
              meritTemplateIds: string[];
              meritTemplateIdsUsedFor:
                | "Check-in only"
                | "Check-in and Check-out"
                | ("Check-in only" & "Check-in and Check-out");
              title: string;
            };
            orgName: string;
          }[];
          hasSignature: boolean;
          id: string;
          isStaffOfAnyForm: boolean;
          isSuperAdmin: boolean;
          isSupervisorOfAnyForm: boolean;
          lastName: string;
        },
        {
          error: string;
        }
      >({
        path: `/api/user/profile`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name GetCheckInMerits
   * @request GET:/api/user/checkin-merits
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  getCheckInMerits = async (
    query: {
      formEntryId: string;
    },
    params: RequestParams = {},
  ): Promise<
    Response<{
      hasMeritAccess: boolean;
      merits: {
        checkInTime: string;
        checkOutTime?: string;
        meritId: string;
        meritTemplateTitle: string;
      }[];
    }>
  > => {
    try {
      const response = await this.request<
        {
          hasMeritAccess: boolean;
          merits: {
            checkInTime: string;
            checkOutTime?: string;
            meritId: string;
            meritTemplateTitle: string;
          }[];
        },
        {
          error: string;
        }
      >({
        path: `/api/user/checkin-merits`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name AddUser
   * @request POST:/api/user/add-user
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  addUser = async (
    body: AddUserPayload,
    params: RequestParams = {},
  ): Promise<
    Response<{
      email: string;
      firstName: string;
      forms: {
        id: string;
        jotformUrl: string;
        name: string;
        operationalPeriod?: {
          meritTemplateIds: string[];
          meritTemplateIdsUsedFor:
            | "Check-in only"
            | "Check-in and Check-out"
            | ("Check-in only" & "Check-in and Check-out");
          title: string;
        };
        orgName: string;
      }[];
      hasSignature: boolean;
      id: string;
      isStaffOfAnyForm: boolean;
      isSuperAdmin: boolean;
      isSupervisorOfAnyForm: boolean;
      lastName: string;
    }>
  > => {
    try {
      const response = await this.request<
        {
          email: string;
          firstName: string;
          forms: {
            id: string;
            jotformUrl: string;
            name: string;
            operationalPeriod?: {
              meritTemplateIds: string[];
              meritTemplateIdsUsedFor:
                | "Check-in only"
                | "Check-in and Check-out"
                | ("Check-in only" & "Check-in and Check-out");
              title: string;
            };
            orgName: string;
          }[];
          hasSignature: boolean;
          id: string;
          isStaffOfAnyForm: boolean;
          isSuperAdmin: boolean;
          isSupervisorOfAnyForm: boolean;
          lastName: string;
        },
        {
          error: string;
        }
      >({
        path: `/api/user/add-user`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
}
