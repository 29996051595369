/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

// merit-vfe: Import custom error for 400 status code
import { Error400 } from "../exceptions/Error400";
// merit-vfe

import {
  AddRoleToFormPayload,
  ChangeFormStatusPayload,
  RemoveRoleFromFormPayload,
  SaveJotformFieldPayload,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

// merit-vfe: Possible response structure for generated api clients
type Response<T = void> =
  | {
      readonly success: false;
      readonly message: string;
    }
  | (T extends void
      ? {
          readonly success: true;
        }
      : {
          readonly success: true;
          readonly data: T;
        });
// merit-vfe

export class Form<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name GetForms
   * @request GET:/api/form/
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  getForms = (params: RequestParams = {}) =>
    this.request<
      {
        forms: {
          contactEmails: string[];
          id: string;
          isActive: boolean;
          jotformFields: (
            | {
                fieldName: string;
                fieldType:
                  | "control_address"
                  | "control_appointment"
                  | "control_button"
                  | "control_calculation"
                  | "control_captcha"
                  | "control_checkbox"
                  | "control_collapse"
                  | "control_datetime"
                  | "control_divider"
                  | "control_dropdown"
                  | "control_email"
                  | "control_fileupload"
                  | "control_fullname"
                  | "control_head"
                  | "control_inline"
                  | "control_matrix"
                  | "control_number"
                  | "control_payment"
                  | "control_phone"
                  | "control_radio"
                  | "control_rating"
                  | "control_scale"
                  | "control_signature"
                  | "control_spinner"
                  | "control_text"
                  | "control_textarea"
                  | "control_textbox"
                  | "control_time"
                  | "control_widget"
                  | "control_pagebreak"
                  | "control_image"
                  | ("control_address" &
                      "control_appointment" &
                      "control_button" &
                      "control_calculation" &
                      "control_captcha" &
                      "control_checkbox" &
                      "control_collapse" &
                      "control_datetime" &
                      "control_divider" &
                      "control_dropdown" &
                      "control_email" &
                      "control_fileupload" &
                      "control_fullname" &
                      "control_head" &
                      "control_inline" &
                      "control_matrix" &
                      "control_number" &
                      "control_payment" &
                      "control_phone" &
                      "control_radio" &
                      "control_rating" &
                      "control_scale" &
                      "control_signature" &
                      "control_spinner" &
                      "control_text" &
                      "control_textarea" &
                      "control_textbox" &
                      "control_time" &
                      "control_widget" &
                      "control_pagebreak" &
                      "control_image");
                id: string;
                uniqueFieldName: string;
                populateFrom?: "lastFormEntry";
              }
            | {
                fieldName: string;
                fieldType:
                  | "control_address"
                  | "control_appointment"
                  | "control_button"
                  | "control_calculation"
                  | "control_captcha"
                  | "control_checkbox"
                  | "control_collapse"
                  | "control_datetime"
                  | "control_divider"
                  | "control_dropdown"
                  | "control_email"
                  | "control_fileupload"
                  | "control_fullname"
                  | "control_head"
                  | "control_inline"
                  | "control_matrix"
                  | "control_number"
                  | "control_payment"
                  | "control_phone"
                  | "control_radio"
                  | "control_rating"
                  | "control_scale"
                  | "control_signature"
                  | "control_spinner"
                  | "control_text"
                  | "control_textarea"
                  | "control_textbox"
                  | "control_time"
                  | "control_widget"
                  | "control_pagebreak"
                  | "control_image"
                  | ("control_address" &
                      "control_appointment" &
                      "control_button" &
                      "control_calculation" &
                      "control_captcha" &
                      "control_checkbox" &
                      "control_collapse" &
                      "control_datetime" &
                      "control_divider" &
                      "control_dropdown" &
                      "control_email" &
                      "control_fileupload" &
                      "control_fullname" &
                      "control_head" &
                      "control_inline" &
                      "control_matrix" &
                      "control_number" &
                      "control_payment" &
                      "control_phone" &
                      "control_radio" &
                      "control_rating" &
                      "control_scale" &
                      "control_signature" &
                      "control_spinner" &
                      "control_text" &
                      "control_textarea" &
                      "control_textbox" &
                      "control_time" &
                      "control_widget" &
                      "control_pagebreak" &
                      "control_image");
                id: string;
                uniqueFieldName: string;
                populateFrom: "merit";
                populateFromMeritFieldId?: string;
                populateFromMeritTemplateId?: string;
              }
            | ({
                fieldName: string;
                fieldType:
                  | "control_address"
                  | "control_appointment"
                  | "control_button"
                  | "control_calculation"
                  | "control_captcha"
                  | "control_checkbox"
                  | "control_collapse"
                  | "control_datetime"
                  | "control_divider"
                  | "control_dropdown"
                  | "control_email"
                  | "control_fileupload"
                  | "control_fullname"
                  | "control_head"
                  | "control_inline"
                  | "control_matrix"
                  | "control_number"
                  | "control_payment"
                  | "control_phone"
                  | "control_radio"
                  | "control_rating"
                  | "control_scale"
                  | "control_signature"
                  | "control_spinner"
                  | "control_text"
                  | "control_textarea"
                  | "control_textbox"
                  | "control_time"
                  | "control_widget"
                  | "control_pagebreak"
                  | "control_image"
                  | ("control_address" &
                      "control_appointment" &
                      "control_button" &
                      "control_calculation" &
                      "control_captcha" &
                      "control_checkbox" &
                      "control_collapse" &
                      "control_datetime" &
                      "control_divider" &
                      "control_dropdown" &
                      "control_email" &
                      "control_fileupload" &
                      "control_fullname" &
                      "control_head" &
                      "control_inline" &
                      "control_matrix" &
                      "control_number" &
                      "control_payment" &
                      "control_phone" &
                      "control_radio" &
                      "control_rating" &
                      "control_scale" &
                      "control_signature" &
                      "control_spinner" &
                      "control_text" &
                      "control_textarea" &
                      "control_textbox" &
                      "control_time" &
                      "control_widget" &
                      "control_pagebreak" &
                      "control_image");
                id: string;
                uniqueFieldName: string;
                populateFrom?: "lastFormEntry";
              } & {
                fieldName: string;
                fieldType:
                  | "control_address"
                  | "control_appointment"
                  | "control_button"
                  | "control_calculation"
                  | "control_captcha"
                  | "control_checkbox"
                  | "control_collapse"
                  | "control_datetime"
                  | "control_divider"
                  | "control_dropdown"
                  | "control_email"
                  | "control_fileupload"
                  | "control_fullname"
                  | "control_head"
                  | "control_inline"
                  | "control_matrix"
                  | "control_number"
                  | "control_payment"
                  | "control_phone"
                  | "control_radio"
                  | "control_rating"
                  | "control_scale"
                  | "control_signature"
                  | "control_spinner"
                  | "control_text"
                  | "control_textarea"
                  | "control_textbox"
                  | "control_time"
                  | "control_widget"
                  | "control_pagebreak"
                  | "control_image"
                  | ("control_address" &
                      "control_appointment" &
                      "control_button" &
                      "control_calculation" &
                      "control_captcha" &
                      "control_checkbox" &
                      "control_collapse" &
                      "control_datetime" &
                      "control_divider" &
                      "control_dropdown" &
                      "control_email" &
                      "control_fileupload" &
                      "control_fullname" &
                      "control_head" &
                      "control_inline" &
                      "control_matrix" &
                      "control_number" &
                      "control_payment" &
                      "control_phone" &
                      "control_radio" &
                      "control_rating" &
                      "control_scale" &
                      "control_signature" &
                      "control_spinner" &
                      "control_text" &
                      "control_textarea" &
                      "control_textbox" &
                      "control_time" &
                      "control_widget" &
                      "control_pagebreak" &
                      "control_image");
                id: string;
                uniqueFieldName: string;
                populateFrom: "merit";
                populateFromMeritFieldId?: string;
                populateFromMeritTemplateId?: string;
              })
          )[];
          jotformId: string;
          jotformPdf: "Generic" | "Smart" | ("Generic" & "Smart");
          jotformUrl: string;
          name: string;
          operationalPeriod?: {
            meritTemplateIds: string[];
            meritTemplateIdsUsedFor:
              | "Check-in only"
              | "Check-in and Check-out"
              | ("Check-in only" & "Check-in and Check-out");
            title: string;
          };
          orgName: string;
          requiredMeritTemplateIds: string[];
          staffs: {
            email: string;
            firstName: string;
            id: string;
            lastName: string;
          }[];
          submitterDateFontSize: number;
          submitterDatePageNumber: number;
          submitterDateX: number;
          submitterDateY: number;
          submitterSignatureHeight: number;
          submitterSignaturePageNumber: number;
          submitterSignatureWidth: number;
          submitterSignatureX: number;
          submitterSignatureY: number;
          supervisorDateFontSize: number;
          supervisorDatePageNumber: number;
          supervisorDateX: number;
          supervisorDateY: number;
          supervisorSignatureHeight: number;
          supervisorSignaturePageNumber: number;
          supervisorSignatureWidth: number;
          supervisorSignatureX: number;
          supervisorSignatureY: number;
          supervisors: {
            email: string;
            firstName: string;
            id: string;
            lastName: string;
          }[];
          timezone: string;
        }[];
      },
      any
    >({
      path: `/api/form/`,
      method: "GET",
      format: "json",
      ...params,
    });

  /**
   * No description
   *
   * @name CreateForm
   * @request POST:/api/form/
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  createForm = async (
    body: {
      form: {
        contactEmails: string[];
        jotformId: string;
        jotformPdf: "Generic" | "Smart" | ("Generic" & "Smart");
        jotformUrl: string;
        name: string;
        operationalPeriod?: {
          meritTemplateIds: string[];
          meritTemplateIdsUsedFor:
            | "Check-in only"
            | "Check-in and Check-out"
            | ("Check-in only" & "Check-in and Check-out");
          title: string;
        };
        orgName: string;
        requiredMeritTemplateIds: string[];
        submitterDatePageNumber: number;
        submitterDateX: number;
        submitterDateY: number;
        submitterSignaturePageNumber: number;
        submitterSignatureX: number;
        submitterSignatureY: number;
        supervisorDatePageNumber: number;
        supervisorDateX: number;
        supervisorDateY: number;
        supervisorSignaturePageNumber: number;
        supervisorSignatureX: number;
        supervisorSignatureY: number;
        timezone: string;
      };
      staffIds: string[];
      supervisorIds: string[];
    },
    params: RequestParams = {},
  ): Promise<
    Response<{
      id?: string;
    }>
  > => {
    try {
      const response = await this.request<
        {
          id?: string;
        },
        {
          error: string;
        }
      >({
        path: `/api/form/`,
        method: "POST",
        body: body,
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name EditForm
   * @request PATCH:/api/form/{id}
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  editForm = async (
    id: string,
    body: {
      form: {
        contactEmails: string[];
        jotformId: string;
        jotformPdf: "Generic" | "Smart" | ("Generic" & "Smart");
        jotformUrl: string;
        name: string;
        operationalPeriod?: {
          meritTemplateIds: string[];
          meritTemplateIdsUsedFor:
            | "Check-in only"
            | "Check-in and Check-out"
            | ("Check-in only" & "Check-in and Check-out");
          title: string;
        };
        orgName: string;
        requiredMeritTemplateIds: string[];
        submitterDatePageNumber: number;
        submitterDateX: number;
        submitterDateY: number;
        submitterSignaturePageNumber: number;
        submitterSignatureX: number;
        submitterSignatureY: number;
        supervisorDatePageNumber: number;
        supervisorDateX: number;
        supervisorDateY: number;
        supervisorSignaturePageNumber: number;
        supervisorSignatureX: number;
        supervisorSignatureY: number;
        timezone: string;
      };
      staffIds: string[];
      supervisorIds: string[];
    },
    params: RequestParams = {},
  ): Promise<
    Response<{
      id?: string;
    }>
  > => {
    try {
      const response = await this.request<
        {
          id?: string;
        },
        {
          error: string;
        }
      >({
        path: `/api/form/${id}`,
        method: "PATCH",
        body: body,
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name DeleteForm
   * @request DELETE:/api/form/{id}
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  deleteForm = async (id: string, params: RequestParams = {}): Promise<Response<void>> => {
    try {
      const response = await this.request<
        void,
        {
          error: string;
        }
      >({
        path: `/api/form/${id}`,
        method: "DELETE",
        ...params,
      });

      return {
        success: true,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name AddRoleToForm
   * @request POST:/api/form/{formId}/add-role
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  addRoleToForm = async (
    formId: string,
    body: AddRoleToFormPayload,
    params: RequestParams = {},
  ): Promise<Response<void>> => {
    try {
      const response = await this.request<
        void,
        {
          error: string;
        }
      >({
        path: `/api/form/${formId}/add-role`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      });

      return {
        success: true,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name RemoveRoleFromForm
   * @request DELETE:/api/form/{formId}/role
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  removeRoleFromForm = async (
    formId: string,
    body: RemoveRoleFromFormPayload,
    params: RequestParams = {},
  ): Promise<Response<void>> => {
    try {
      const response = await this.request<
        void,
        {
          error: string;
        }
      >({
        path: `/api/form/${formId}/role`,
        method: "DELETE",
        body: body,
        type: ContentType.Json,
        ...params,
      });

      return {
        success: true,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name ChangeFormStatus
   * @request PATCH:/api/form/{formId}/status
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  changeFormStatus = async (
    formId: string,
    body: ChangeFormStatusPayload,
    params: RequestParams = {},
  ): Promise<Response<void>> => {
    try {
      const response = await this.request<
        void,
        {
          error: string;
        }
      >({
        path: `/api/form/${formId}/status`,
        method: "PATCH",
        body: body,
        type: ContentType.Json,
        ...params,
      });

      return {
        success: true,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name GetFormById
   * @request GET:/api/form/{formId}
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  getFormById = async (
    formId: string,
    params: RequestParams = {},
  ): Promise<
    Response<{
      form: {
        contactEmails: string[];
        id: string;
        isActive: boolean;
        jotformFields: (
          | {
              fieldName: string;
              fieldType:
                | "control_address"
                | "control_appointment"
                | "control_button"
                | "control_calculation"
                | "control_captcha"
                | "control_checkbox"
                | "control_collapse"
                | "control_datetime"
                | "control_divider"
                | "control_dropdown"
                | "control_email"
                | "control_fileupload"
                | "control_fullname"
                | "control_head"
                | "control_inline"
                | "control_matrix"
                | "control_number"
                | "control_payment"
                | "control_phone"
                | "control_radio"
                | "control_rating"
                | "control_scale"
                | "control_signature"
                | "control_spinner"
                | "control_text"
                | "control_textarea"
                | "control_textbox"
                | "control_time"
                | "control_widget"
                | "control_pagebreak"
                | "control_image"
                | ("control_address" &
                    "control_appointment" &
                    "control_button" &
                    "control_calculation" &
                    "control_captcha" &
                    "control_checkbox" &
                    "control_collapse" &
                    "control_datetime" &
                    "control_divider" &
                    "control_dropdown" &
                    "control_email" &
                    "control_fileupload" &
                    "control_fullname" &
                    "control_head" &
                    "control_inline" &
                    "control_matrix" &
                    "control_number" &
                    "control_payment" &
                    "control_phone" &
                    "control_radio" &
                    "control_rating" &
                    "control_scale" &
                    "control_signature" &
                    "control_spinner" &
                    "control_text" &
                    "control_textarea" &
                    "control_textbox" &
                    "control_time" &
                    "control_widget" &
                    "control_pagebreak" &
                    "control_image");
              id: string;
              uniqueFieldName: string;
              populateFrom?: "lastFormEntry";
            }
          | {
              fieldName: string;
              fieldType:
                | "control_address"
                | "control_appointment"
                | "control_button"
                | "control_calculation"
                | "control_captcha"
                | "control_checkbox"
                | "control_collapse"
                | "control_datetime"
                | "control_divider"
                | "control_dropdown"
                | "control_email"
                | "control_fileupload"
                | "control_fullname"
                | "control_head"
                | "control_inline"
                | "control_matrix"
                | "control_number"
                | "control_payment"
                | "control_phone"
                | "control_radio"
                | "control_rating"
                | "control_scale"
                | "control_signature"
                | "control_spinner"
                | "control_text"
                | "control_textarea"
                | "control_textbox"
                | "control_time"
                | "control_widget"
                | "control_pagebreak"
                | "control_image"
                | ("control_address" &
                    "control_appointment" &
                    "control_button" &
                    "control_calculation" &
                    "control_captcha" &
                    "control_checkbox" &
                    "control_collapse" &
                    "control_datetime" &
                    "control_divider" &
                    "control_dropdown" &
                    "control_email" &
                    "control_fileupload" &
                    "control_fullname" &
                    "control_head" &
                    "control_inline" &
                    "control_matrix" &
                    "control_number" &
                    "control_payment" &
                    "control_phone" &
                    "control_radio" &
                    "control_rating" &
                    "control_scale" &
                    "control_signature" &
                    "control_spinner" &
                    "control_text" &
                    "control_textarea" &
                    "control_textbox" &
                    "control_time" &
                    "control_widget" &
                    "control_pagebreak" &
                    "control_image");
              id: string;
              uniqueFieldName: string;
              populateFrom: "merit";
              populateFromMeritFieldId?: string;
              populateFromMeritTemplateId?: string;
            }
          | ({
              fieldName: string;
              fieldType:
                | "control_address"
                | "control_appointment"
                | "control_button"
                | "control_calculation"
                | "control_captcha"
                | "control_checkbox"
                | "control_collapse"
                | "control_datetime"
                | "control_divider"
                | "control_dropdown"
                | "control_email"
                | "control_fileupload"
                | "control_fullname"
                | "control_head"
                | "control_inline"
                | "control_matrix"
                | "control_number"
                | "control_payment"
                | "control_phone"
                | "control_radio"
                | "control_rating"
                | "control_scale"
                | "control_signature"
                | "control_spinner"
                | "control_text"
                | "control_textarea"
                | "control_textbox"
                | "control_time"
                | "control_widget"
                | "control_pagebreak"
                | "control_image"
                | ("control_address" &
                    "control_appointment" &
                    "control_button" &
                    "control_calculation" &
                    "control_captcha" &
                    "control_checkbox" &
                    "control_collapse" &
                    "control_datetime" &
                    "control_divider" &
                    "control_dropdown" &
                    "control_email" &
                    "control_fileupload" &
                    "control_fullname" &
                    "control_head" &
                    "control_inline" &
                    "control_matrix" &
                    "control_number" &
                    "control_payment" &
                    "control_phone" &
                    "control_radio" &
                    "control_rating" &
                    "control_scale" &
                    "control_signature" &
                    "control_spinner" &
                    "control_text" &
                    "control_textarea" &
                    "control_textbox" &
                    "control_time" &
                    "control_widget" &
                    "control_pagebreak" &
                    "control_image");
              id: string;
              uniqueFieldName: string;
              populateFrom?: "lastFormEntry";
            } & {
              fieldName: string;
              fieldType:
                | "control_address"
                | "control_appointment"
                | "control_button"
                | "control_calculation"
                | "control_captcha"
                | "control_checkbox"
                | "control_collapse"
                | "control_datetime"
                | "control_divider"
                | "control_dropdown"
                | "control_email"
                | "control_fileupload"
                | "control_fullname"
                | "control_head"
                | "control_inline"
                | "control_matrix"
                | "control_number"
                | "control_payment"
                | "control_phone"
                | "control_radio"
                | "control_rating"
                | "control_scale"
                | "control_signature"
                | "control_spinner"
                | "control_text"
                | "control_textarea"
                | "control_textbox"
                | "control_time"
                | "control_widget"
                | "control_pagebreak"
                | "control_image"
                | ("control_address" &
                    "control_appointment" &
                    "control_button" &
                    "control_calculation" &
                    "control_captcha" &
                    "control_checkbox" &
                    "control_collapse" &
                    "control_datetime" &
                    "control_divider" &
                    "control_dropdown" &
                    "control_email" &
                    "control_fileupload" &
                    "control_fullname" &
                    "control_head" &
                    "control_inline" &
                    "control_matrix" &
                    "control_number" &
                    "control_payment" &
                    "control_phone" &
                    "control_radio" &
                    "control_rating" &
                    "control_scale" &
                    "control_signature" &
                    "control_spinner" &
                    "control_text" &
                    "control_textarea" &
                    "control_textbox" &
                    "control_time" &
                    "control_widget" &
                    "control_pagebreak" &
                    "control_image");
              id: string;
              uniqueFieldName: string;
              populateFrom: "merit";
              populateFromMeritFieldId?: string;
              populateFromMeritTemplateId?: string;
            })
        )[];
        jotformId: string;
        jotformPdf: "Generic" | "Smart" | ("Generic" & "Smart");
        jotformUrl: string;
        name: string;
        operationalPeriod?: {
          meritTemplateIds: string[];
          meritTemplateIdsUsedFor:
            | "Check-in only"
            | "Check-in and Check-out"
            | ("Check-in only" & "Check-in and Check-out");
          title: string;
        };
        orgName: string;
        requiredMeritTemplateIds: string[];
        staffs: {
          email: string;
          firstName: string;
          id: string;
          lastName: string;
        }[];
        submitterDateFontSize: number;
        submitterDatePageNumber: number;
        submitterDateX: number;
        submitterDateY: number;
        submitterSignatureHeight: number;
        submitterSignaturePageNumber: number;
        submitterSignatureWidth: number;
        submitterSignatureX: number;
        submitterSignatureY: number;
        supervisorDateFontSize: number;
        supervisorDatePageNumber: number;
        supervisorDateX: number;
        supervisorDateY: number;
        supervisorSignatureHeight: number;
        supervisorSignaturePageNumber: number;
        supervisorSignatureWidth: number;
        supervisorSignatureX: number;
        supervisorSignatureY: number;
        supervisors: {
          email: string;
          firstName: string;
          id: string;
          lastName: string;
        }[];
        timezone: string;
      };
    }>
  > => {
    try {
      const response = await this.request<
        {
          form: {
            contactEmails: string[];
            id: string;
            isActive: boolean;
            jotformFields: (
              | {
                  fieldName: string;
                  fieldType:
                    | "control_address"
                    | "control_appointment"
                    | "control_button"
                    | "control_calculation"
                    | "control_captcha"
                    | "control_checkbox"
                    | "control_collapse"
                    | "control_datetime"
                    | "control_divider"
                    | "control_dropdown"
                    | "control_email"
                    | "control_fileupload"
                    | "control_fullname"
                    | "control_head"
                    | "control_inline"
                    | "control_matrix"
                    | "control_number"
                    | "control_payment"
                    | "control_phone"
                    | "control_radio"
                    | "control_rating"
                    | "control_scale"
                    | "control_signature"
                    | "control_spinner"
                    | "control_text"
                    | "control_textarea"
                    | "control_textbox"
                    | "control_time"
                    | "control_widget"
                    | "control_pagebreak"
                    | "control_image"
                    | ("control_address" &
                        "control_appointment" &
                        "control_button" &
                        "control_calculation" &
                        "control_captcha" &
                        "control_checkbox" &
                        "control_collapse" &
                        "control_datetime" &
                        "control_divider" &
                        "control_dropdown" &
                        "control_email" &
                        "control_fileupload" &
                        "control_fullname" &
                        "control_head" &
                        "control_inline" &
                        "control_matrix" &
                        "control_number" &
                        "control_payment" &
                        "control_phone" &
                        "control_radio" &
                        "control_rating" &
                        "control_scale" &
                        "control_signature" &
                        "control_spinner" &
                        "control_text" &
                        "control_textarea" &
                        "control_textbox" &
                        "control_time" &
                        "control_widget" &
                        "control_pagebreak" &
                        "control_image");
                  id: string;
                  uniqueFieldName: string;
                  populateFrom?: "lastFormEntry";
                }
              | {
                  fieldName: string;
                  fieldType:
                    | "control_address"
                    | "control_appointment"
                    | "control_button"
                    | "control_calculation"
                    | "control_captcha"
                    | "control_checkbox"
                    | "control_collapse"
                    | "control_datetime"
                    | "control_divider"
                    | "control_dropdown"
                    | "control_email"
                    | "control_fileupload"
                    | "control_fullname"
                    | "control_head"
                    | "control_inline"
                    | "control_matrix"
                    | "control_number"
                    | "control_payment"
                    | "control_phone"
                    | "control_radio"
                    | "control_rating"
                    | "control_scale"
                    | "control_signature"
                    | "control_spinner"
                    | "control_text"
                    | "control_textarea"
                    | "control_textbox"
                    | "control_time"
                    | "control_widget"
                    | "control_pagebreak"
                    | "control_image"
                    | ("control_address" &
                        "control_appointment" &
                        "control_button" &
                        "control_calculation" &
                        "control_captcha" &
                        "control_checkbox" &
                        "control_collapse" &
                        "control_datetime" &
                        "control_divider" &
                        "control_dropdown" &
                        "control_email" &
                        "control_fileupload" &
                        "control_fullname" &
                        "control_head" &
                        "control_inline" &
                        "control_matrix" &
                        "control_number" &
                        "control_payment" &
                        "control_phone" &
                        "control_radio" &
                        "control_rating" &
                        "control_scale" &
                        "control_signature" &
                        "control_spinner" &
                        "control_text" &
                        "control_textarea" &
                        "control_textbox" &
                        "control_time" &
                        "control_widget" &
                        "control_pagebreak" &
                        "control_image");
                  id: string;
                  uniqueFieldName: string;
                  populateFrom: "merit";
                  populateFromMeritFieldId?: string;
                  populateFromMeritTemplateId?: string;
                }
              | ({
                  fieldName: string;
                  fieldType:
                    | "control_address"
                    | "control_appointment"
                    | "control_button"
                    | "control_calculation"
                    | "control_captcha"
                    | "control_checkbox"
                    | "control_collapse"
                    | "control_datetime"
                    | "control_divider"
                    | "control_dropdown"
                    | "control_email"
                    | "control_fileupload"
                    | "control_fullname"
                    | "control_head"
                    | "control_inline"
                    | "control_matrix"
                    | "control_number"
                    | "control_payment"
                    | "control_phone"
                    | "control_radio"
                    | "control_rating"
                    | "control_scale"
                    | "control_signature"
                    | "control_spinner"
                    | "control_text"
                    | "control_textarea"
                    | "control_textbox"
                    | "control_time"
                    | "control_widget"
                    | "control_pagebreak"
                    | "control_image"
                    | ("control_address" &
                        "control_appointment" &
                        "control_button" &
                        "control_calculation" &
                        "control_captcha" &
                        "control_checkbox" &
                        "control_collapse" &
                        "control_datetime" &
                        "control_divider" &
                        "control_dropdown" &
                        "control_email" &
                        "control_fileupload" &
                        "control_fullname" &
                        "control_head" &
                        "control_inline" &
                        "control_matrix" &
                        "control_number" &
                        "control_payment" &
                        "control_phone" &
                        "control_radio" &
                        "control_rating" &
                        "control_scale" &
                        "control_signature" &
                        "control_spinner" &
                        "control_text" &
                        "control_textarea" &
                        "control_textbox" &
                        "control_time" &
                        "control_widget" &
                        "control_pagebreak" &
                        "control_image");
                  id: string;
                  uniqueFieldName: string;
                  populateFrom?: "lastFormEntry";
                } & {
                  fieldName: string;
                  fieldType:
                    | "control_address"
                    | "control_appointment"
                    | "control_button"
                    | "control_calculation"
                    | "control_captcha"
                    | "control_checkbox"
                    | "control_collapse"
                    | "control_datetime"
                    | "control_divider"
                    | "control_dropdown"
                    | "control_email"
                    | "control_fileupload"
                    | "control_fullname"
                    | "control_head"
                    | "control_inline"
                    | "control_matrix"
                    | "control_number"
                    | "control_payment"
                    | "control_phone"
                    | "control_radio"
                    | "control_rating"
                    | "control_scale"
                    | "control_signature"
                    | "control_spinner"
                    | "control_text"
                    | "control_textarea"
                    | "control_textbox"
                    | "control_time"
                    | "control_widget"
                    | "control_pagebreak"
                    | "control_image"
                    | ("control_address" &
                        "control_appointment" &
                        "control_button" &
                        "control_calculation" &
                        "control_captcha" &
                        "control_checkbox" &
                        "control_collapse" &
                        "control_datetime" &
                        "control_divider" &
                        "control_dropdown" &
                        "control_email" &
                        "control_fileupload" &
                        "control_fullname" &
                        "control_head" &
                        "control_inline" &
                        "control_matrix" &
                        "control_number" &
                        "control_payment" &
                        "control_phone" &
                        "control_radio" &
                        "control_rating" &
                        "control_scale" &
                        "control_signature" &
                        "control_spinner" &
                        "control_text" &
                        "control_textarea" &
                        "control_textbox" &
                        "control_time" &
                        "control_widget" &
                        "control_pagebreak" &
                        "control_image");
                  id: string;
                  uniqueFieldName: string;
                  populateFrom: "merit";
                  populateFromMeritFieldId?: string;
                  populateFromMeritTemplateId?: string;
                })
            )[];
            jotformId: string;
            jotformPdf: "Generic" | "Smart" | ("Generic" & "Smart");
            jotformUrl: string;
            name: string;
            operationalPeriod?: {
              meritTemplateIds: string[];
              meritTemplateIdsUsedFor:
                | "Check-in only"
                | "Check-in and Check-out"
                | ("Check-in only" & "Check-in and Check-out");
              title: string;
            };
            orgName: string;
            requiredMeritTemplateIds: string[];
            staffs: {
              email: string;
              firstName: string;
              id: string;
              lastName: string;
            }[];
            submitterDateFontSize: number;
            submitterDatePageNumber: number;
            submitterDateX: number;
            submitterDateY: number;
            submitterSignatureHeight: number;
            submitterSignaturePageNumber: number;
            submitterSignatureWidth: number;
            submitterSignatureX: number;
            submitterSignatureY: number;
            supervisorDateFontSize: number;
            supervisorDatePageNumber: number;
            supervisorDateX: number;
            supervisorDateY: number;
            supervisorSignatureHeight: number;
            supervisorSignaturePageNumber: number;
            supervisorSignatureWidth: number;
            supervisorSignatureX: number;
            supervisorSignatureY: number;
            supervisors: {
              email: string;
              firstName: string;
              id: string;
              lastName: string;
            }[];
            timezone: string;
          };
        },
        {
          error: string;
        }
      >({
        path: `/api/form/${formId}`,
        method: "GET",
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name SaveJotformField
   * @request POST:/api/form/{formId}/prepopulate
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  saveJotformField = async (
    formId: string,
    body: SaveJotformFieldPayload,
    params: RequestParams = {},
  ): Promise<Response<void>> => {
    try {
      const response = await this.request<
        void,
        {
          error: string;
        }
      >({
        path: `/api/form/${formId}/prepopulate`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      });

      return {
        success: true,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name SyncJotformFields
   * @request PATCH:/api/form/{formId}/sync
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  syncJotformFields = async (
    formId: string,
    params: RequestParams = {},
  ): Promise<
    Response<{
      jotformFields: (
        | {
            fieldName: string;
            fieldType:
              | "control_address"
              | "control_appointment"
              | "control_button"
              | "control_calculation"
              | "control_captcha"
              | "control_checkbox"
              | "control_collapse"
              | "control_datetime"
              | "control_divider"
              | "control_dropdown"
              | "control_email"
              | "control_fileupload"
              | "control_fullname"
              | "control_head"
              | "control_inline"
              | "control_matrix"
              | "control_number"
              | "control_payment"
              | "control_phone"
              | "control_radio"
              | "control_rating"
              | "control_scale"
              | "control_signature"
              | "control_spinner"
              | "control_text"
              | "control_textarea"
              | "control_textbox"
              | "control_time"
              | "control_widget"
              | "control_pagebreak"
              | "control_image"
              | ("control_address" &
                  "control_appointment" &
                  "control_button" &
                  "control_calculation" &
                  "control_captcha" &
                  "control_checkbox" &
                  "control_collapse" &
                  "control_datetime" &
                  "control_divider" &
                  "control_dropdown" &
                  "control_email" &
                  "control_fileupload" &
                  "control_fullname" &
                  "control_head" &
                  "control_inline" &
                  "control_matrix" &
                  "control_number" &
                  "control_payment" &
                  "control_phone" &
                  "control_radio" &
                  "control_rating" &
                  "control_scale" &
                  "control_signature" &
                  "control_spinner" &
                  "control_text" &
                  "control_textarea" &
                  "control_textbox" &
                  "control_time" &
                  "control_widget" &
                  "control_pagebreak" &
                  "control_image");
            id: string;
            uniqueFieldName: string;
            populateFrom?: "lastFormEntry";
          }
        | {
            fieldName: string;
            fieldType:
              | "control_address"
              | "control_appointment"
              | "control_button"
              | "control_calculation"
              | "control_captcha"
              | "control_checkbox"
              | "control_collapse"
              | "control_datetime"
              | "control_divider"
              | "control_dropdown"
              | "control_email"
              | "control_fileupload"
              | "control_fullname"
              | "control_head"
              | "control_inline"
              | "control_matrix"
              | "control_number"
              | "control_payment"
              | "control_phone"
              | "control_radio"
              | "control_rating"
              | "control_scale"
              | "control_signature"
              | "control_spinner"
              | "control_text"
              | "control_textarea"
              | "control_textbox"
              | "control_time"
              | "control_widget"
              | "control_pagebreak"
              | "control_image"
              | ("control_address" &
                  "control_appointment" &
                  "control_button" &
                  "control_calculation" &
                  "control_captcha" &
                  "control_checkbox" &
                  "control_collapse" &
                  "control_datetime" &
                  "control_divider" &
                  "control_dropdown" &
                  "control_email" &
                  "control_fileupload" &
                  "control_fullname" &
                  "control_head" &
                  "control_inline" &
                  "control_matrix" &
                  "control_number" &
                  "control_payment" &
                  "control_phone" &
                  "control_radio" &
                  "control_rating" &
                  "control_scale" &
                  "control_signature" &
                  "control_spinner" &
                  "control_text" &
                  "control_textarea" &
                  "control_textbox" &
                  "control_time" &
                  "control_widget" &
                  "control_pagebreak" &
                  "control_image");
            id: string;
            uniqueFieldName: string;
            populateFrom: "merit";
            populateFromMeritFieldId?: string;
            populateFromMeritTemplateId?: string;
          }
        | ({
            fieldName: string;
            fieldType:
              | "control_address"
              | "control_appointment"
              | "control_button"
              | "control_calculation"
              | "control_captcha"
              | "control_checkbox"
              | "control_collapse"
              | "control_datetime"
              | "control_divider"
              | "control_dropdown"
              | "control_email"
              | "control_fileupload"
              | "control_fullname"
              | "control_head"
              | "control_inline"
              | "control_matrix"
              | "control_number"
              | "control_payment"
              | "control_phone"
              | "control_radio"
              | "control_rating"
              | "control_scale"
              | "control_signature"
              | "control_spinner"
              | "control_text"
              | "control_textarea"
              | "control_textbox"
              | "control_time"
              | "control_widget"
              | "control_pagebreak"
              | "control_image"
              | ("control_address" &
                  "control_appointment" &
                  "control_button" &
                  "control_calculation" &
                  "control_captcha" &
                  "control_checkbox" &
                  "control_collapse" &
                  "control_datetime" &
                  "control_divider" &
                  "control_dropdown" &
                  "control_email" &
                  "control_fileupload" &
                  "control_fullname" &
                  "control_head" &
                  "control_inline" &
                  "control_matrix" &
                  "control_number" &
                  "control_payment" &
                  "control_phone" &
                  "control_radio" &
                  "control_rating" &
                  "control_scale" &
                  "control_signature" &
                  "control_spinner" &
                  "control_text" &
                  "control_textarea" &
                  "control_textbox" &
                  "control_time" &
                  "control_widget" &
                  "control_pagebreak" &
                  "control_image");
            id: string;
            uniqueFieldName: string;
            populateFrom?: "lastFormEntry";
          } & {
            fieldName: string;
            fieldType:
              | "control_address"
              | "control_appointment"
              | "control_button"
              | "control_calculation"
              | "control_captcha"
              | "control_checkbox"
              | "control_collapse"
              | "control_datetime"
              | "control_divider"
              | "control_dropdown"
              | "control_email"
              | "control_fileupload"
              | "control_fullname"
              | "control_head"
              | "control_inline"
              | "control_matrix"
              | "control_number"
              | "control_payment"
              | "control_phone"
              | "control_radio"
              | "control_rating"
              | "control_scale"
              | "control_signature"
              | "control_spinner"
              | "control_text"
              | "control_textarea"
              | "control_textbox"
              | "control_time"
              | "control_widget"
              | "control_pagebreak"
              | "control_image"
              | ("control_address" &
                  "control_appointment" &
                  "control_button" &
                  "control_calculation" &
                  "control_captcha" &
                  "control_checkbox" &
                  "control_collapse" &
                  "control_datetime" &
                  "control_divider" &
                  "control_dropdown" &
                  "control_email" &
                  "control_fileupload" &
                  "control_fullname" &
                  "control_head" &
                  "control_inline" &
                  "control_matrix" &
                  "control_number" &
                  "control_payment" &
                  "control_phone" &
                  "control_radio" &
                  "control_rating" &
                  "control_scale" &
                  "control_signature" &
                  "control_spinner" &
                  "control_text" &
                  "control_textarea" &
                  "control_textbox" &
                  "control_time" &
                  "control_widget" &
                  "control_pagebreak" &
                  "control_image");
            id: string;
            uniqueFieldName: string;
            populateFrom: "merit";
            populateFromMeritFieldId?: string;
            populateFromMeritTemplateId?: string;
          })
      )[];
    }>
  > => {
    try {
      const response = await this.request<
        {
          jotformFields: (
            | {
                fieldName: string;
                fieldType:
                  | "control_address"
                  | "control_appointment"
                  | "control_button"
                  | "control_calculation"
                  | "control_captcha"
                  | "control_checkbox"
                  | "control_collapse"
                  | "control_datetime"
                  | "control_divider"
                  | "control_dropdown"
                  | "control_email"
                  | "control_fileupload"
                  | "control_fullname"
                  | "control_head"
                  | "control_inline"
                  | "control_matrix"
                  | "control_number"
                  | "control_payment"
                  | "control_phone"
                  | "control_radio"
                  | "control_rating"
                  | "control_scale"
                  | "control_signature"
                  | "control_spinner"
                  | "control_text"
                  | "control_textarea"
                  | "control_textbox"
                  | "control_time"
                  | "control_widget"
                  | "control_pagebreak"
                  | "control_image"
                  | ("control_address" &
                      "control_appointment" &
                      "control_button" &
                      "control_calculation" &
                      "control_captcha" &
                      "control_checkbox" &
                      "control_collapse" &
                      "control_datetime" &
                      "control_divider" &
                      "control_dropdown" &
                      "control_email" &
                      "control_fileupload" &
                      "control_fullname" &
                      "control_head" &
                      "control_inline" &
                      "control_matrix" &
                      "control_number" &
                      "control_payment" &
                      "control_phone" &
                      "control_radio" &
                      "control_rating" &
                      "control_scale" &
                      "control_signature" &
                      "control_spinner" &
                      "control_text" &
                      "control_textarea" &
                      "control_textbox" &
                      "control_time" &
                      "control_widget" &
                      "control_pagebreak" &
                      "control_image");
                id: string;
                uniqueFieldName: string;
                populateFrom?: "lastFormEntry";
              }
            | {
                fieldName: string;
                fieldType:
                  | "control_address"
                  | "control_appointment"
                  | "control_button"
                  | "control_calculation"
                  | "control_captcha"
                  | "control_checkbox"
                  | "control_collapse"
                  | "control_datetime"
                  | "control_divider"
                  | "control_dropdown"
                  | "control_email"
                  | "control_fileupload"
                  | "control_fullname"
                  | "control_head"
                  | "control_inline"
                  | "control_matrix"
                  | "control_number"
                  | "control_payment"
                  | "control_phone"
                  | "control_radio"
                  | "control_rating"
                  | "control_scale"
                  | "control_signature"
                  | "control_spinner"
                  | "control_text"
                  | "control_textarea"
                  | "control_textbox"
                  | "control_time"
                  | "control_widget"
                  | "control_pagebreak"
                  | "control_image"
                  | ("control_address" &
                      "control_appointment" &
                      "control_button" &
                      "control_calculation" &
                      "control_captcha" &
                      "control_checkbox" &
                      "control_collapse" &
                      "control_datetime" &
                      "control_divider" &
                      "control_dropdown" &
                      "control_email" &
                      "control_fileupload" &
                      "control_fullname" &
                      "control_head" &
                      "control_inline" &
                      "control_matrix" &
                      "control_number" &
                      "control_payment" &
                      "control_phone" &
                      "control_radio" &
                      "control_rating" &
                      "control_scale" &
                      "control_signature" &
                      "control_spinner" &
                      "control_text" &
                      "control_textarea" &
                      "control_textbox" &
                      "control_time" &
                      "control_widget" &
                      "control_pagebreak" &
                      "control_image");
                id: string;
                uniqueFieldName: string;
                populateFrom: "merit";
                populateFromMeritFieldId?: string;
                populateFromMeritTemplateId?: string;
              }
            | ({
                fieldName: string;
                fieldType:
                  | "control_address"
                  | "control_appointment"
                  | "control_button"
                  | "control_calculation"
                  | "control_captcha"
                  | "control_checkbox"
                  | "control_collapse"
                  | "control_datetime"
                  | "control_divider"
                  | "control_dropdown"
                  | "control_email"
                  | "control_fileupload"
                  | "control_fullname"
                  | "control_head"
                  | "control_inline"
                  | "control_matrix"
                  | "control_number"
                  | "control_payment"
                  | "control_phone"
                  | "control_radio"
                  | "control_rating"
                  | "control_scale"
                  | "control_signature"
                  | "control_spinner"
                  | "control_text"
                  | "control_textarea"
                  | "control_textbox"
                  | "control_time"
                  | "control_widget"
                  | "control_pagebreak"
                  | "control_image"
                  | ("control_address" &
                      "control_appointment" &
                      "control_button" &
                      "control_calculation" &
                      "control_captcha" &
                      "control_checkbox" &
                      "control_collapse" &
                      "control_datetime" &
                      "control_divider" &
                      "control_dropdown" &
                      "control_email" &
                      "control_fileupload" &
                      "control_fullname" &
                      "control_head" &
                      "control_inline" &
                      "control_matrix" &
                      "control_number" &
                      "control_payment" &
                      "control_phone" &
                      "control_radio" &
                      "control_rating" &
                      "control_scale" &
                      "control_signature" &
                      "control_spinner" &
                      "control_text" &
                      "control_textarea" &
                      "control_textbox" &
                      "control_time" &
                      "control_widget" &
                      "control_pagebreak" &
                      "control_image");
                id: string;
                uniqueFieldName: string;
                populateFrom?: "lastFormEntry";
              } & {
                fieldName: string;
                fieldType:
                  | "control_address"
                  | "control_appointment"
                  | "control_button"
                  | "control_calculation"
                  | "control_captcha"
                  | "control_checkbox"
                  | "control_collapse"
                  | "control_datetime"
                  | "control_divider"
                  | "control_dropdown"
                  | "control_email"
                  | "control_fileupload"
                  | "control_fullname"
                  | "control_head"
                  | "control_inline"
                  | "control_matrix"
                  | "control_number"
                  | "control_payment"
                  | "control_phone"
                  | "control_radio"
                  | "control_rating"
                  | "control_scale"
                  | "control_signature"
                  | "control_spinner"
                  | "control_text"
                  | "control_textarea"
                  | "control_textbox"
                  | "control_time"
                  | "control_widget"
                  | "control_pagebreak"
                  | "control_image"
                  | ("control_address" &
                      "control_appointment" &
                      "control_button" &
                      "control_calculation" &
                      "control_captcha" &
                      "control_checkbox" &
                      "control_collapse" &
                      "control_datetime" &
                      "control_divider" &
                      "control_dropdown" &
                      "control_email" &
                      "control_fileupload" &
                      "control_fullname" &
                      "control_head" &
                      "control_inline" &
                      "control_matrix" &
                      "control_number" &
                      "control_payment" &
                      "control_phone" &
                      "control_radio" &
                      "control_rating" &
                      "control_scale" &
                      "control_signature" &
                      "control_spinner" &
                      "control_text" &
                      "control_textarea" &
                      "control_textbox" &
                      "control_time" &
                      "control_widget" &
                      "control_pagebreak" &
                      "control_image");
                id: string;
                uniqueFieldName: string;
                populateFrom: "merit";
                populateFromMeritFieldId?: string;
                populateFromMeritTemplateId?: string;
              })
          )[];
        },
        {
          error: string;
        }
      >({
        path: `/api/form/${formId}/sync`,
        method: "PATCH",
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
}
