import { Colours } from "./Colours";
import type { ThemeConfig } from "antd/es/config-provider/context";

export const theme: ThemeConfig = {
  token: {
    borderRadius: 2,
    colorBgLayout: Colours.backgroundGrey,
    colorLink: Colours.primary,
    colorPrimary: Colours.primary,
    colorPrimaryBg: Colours.primary,
    fontFamily: "ProximaNova-Regular",
    fontSizeIcon: 10,
  },
};
