import { AlertMessage } from "../utils/alertMessage";
import { routes } from "../constants/Routes";
import { useApi } from "../utils/useApi";
import { useCallback } from "react";
import { useLogout } from "../hooks/auth";
import { useNavigate } from "react-router-dom";
import { useStore } from "../store/store";
import { v4 as uuidv4 } from "uuid";
import type { State } from "../store/store";

const LOGIN_WITH_MERIT_STATE_KEY = "LOGIN_WITH_MERIT_STATE_KEY";
const setUserSelector = (state: State) => state.setUser;
const hasUserCompletedProfileSelector = (state: State) => state.hasUserCompletedProfile;

export function useLoginService() {
  const navigate = useNavigate();
  const setUser = useStore(setUserSelector);
  const hasUserCompletedProfile = useStore(hasUserCompletedProfileSelector);
  const { loginClient } = useApi();
  const logout = useLogout();

  const loginWithMerit = useCallback(async () => {
    try {
      // Store random nonce in sessionStorage
      const state = uuidv4();
      sessionStorage.setItem(LOGIN_WITH_MERIT_STATE_KEY, state);

      const { url } = await loginClient.loginWithMerit({
        state,
      });

      window.location.assign(url);
    } catch (error: unknown) {
      // If anything goes wrong, don't store the state nonce
      sessionStorage.removeItem(LOGIN_WITH_MERIT_STATE_KEY);

      // Rethrow, because we actually just wanted to do our best to ensure sessionStorage is cleaned up, not handle the error
      throw error;
    }
  }, [loginClient]);

  const login = useCallback(
    async (memberIdToken: string) => {
      try {
        const response = await loginClient.login({ memberIdToken });
        if (response.success) {
          setUser(response.data);
          navigate(hasUserCompletedProfile() ? routes.FormHistory : routes.Welcome, { replace: true });
        } else {
          AlertMessage.error(response.message, 5, logout);
        }
      } catch (error: unknown) {
        AlertMessage.error("There was a problem logging in", 3, logout);
      }
    },
    [hasUserCompletedProfile, navigate, setUser, loginClient, logout]
  );

  return {
    login,
    loginWithMerit,
  };
}
