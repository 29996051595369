import { Auth0Provider } from "@auth0/auth0-react";
import { None } from "../../utils/None";
import type { FCWithChildren } from "../../constants/component";

const allAuthScopes =
  "read:container read:agent_entity_link create:agent_entity_link iss:get-template iss:get-field-kind iss:get-container agents:accept-tos agents:get-agent agents:read-entity-links agents:link-entity-agent";

const AuthProvider: FCWithChildren = ({ children }) => {
  const { REACT_APP_AUTH0_AUDIENCE_URL, REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_DOMAIN } = process.env;

  if (None(REACT_APP_AUTH0_CLIENT_ID) || None(REACT_APP_AUTH0_DOMAIN)) {
    return null;
  }

  return (
    <Auth0Provider
      authorizationParams={{ audience: REACT_APP_AUTH0_AUDIENCE_URL, scope: allAuthScopes }}
      cacheLocation="localstorage"
      clientId={REACT_APP_AUTH0_CLIENT_ID}
      domain={REACT_APP_AUTH0_DOMAIN}
    >
      {children}
    </Auth0Provider>
  );
};

export { AuthProvider };
