import { Some } from "../utils/Some";
import { persist } from "zustand/middleware";
import create from "zustand";
import type { PersistOptions } from "zustand/middleware";
import type { StateCreator } from "zustand";
import type { LoginResponse as User } from "../__generated__/LoginRoute";

export type State = {
  readonly clearUser: () => void;
  readonly hasUserCompletedProfile: () => boolean;
  readonly setUser: (user: User) => void;
  readonly user?: User;
};

type TypedPersist = (config: StateCreator<State>, options: PersistOptions<State>) => StateCreator<State>;

const useStore = create<State>(
  (persist as unknown as TypedPersist)(
    (set, get) => ({
      clearUser: () => {
        set(() => ({
          user: undefined,
        }));
      },
      hasUserCompletedProfile: () => {
        const user = get().user;

        return Some(user) && user.hasSignature;
      },
      setUser: (user: User) => {
        set(() => ({
          user,
        }));
      },
      user: undefined,
    }),
    {
      name: "user-storage",
    }
  )
);

export { useStore };
