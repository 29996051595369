import { message } from "antd";
import type { ReactNode } from "react";

const error = (content: ReactNode | string, duration = 3, onClose?: () => void) => {
  message.error({
    content,
    duration,
    onClick: () => {
      message.destroy();
      onClose?.();
    },
    onClose,
  });
};
const success = (content: ReactNode | string, duration = 3, onClose?: () => void) => {
  message.success({
    content,
    duration,
    onClick: () => {
      message.destroy();
      onClose?.();
    },
    onClose,
  });
};

const warning = (content: ReactNode | string, duration = 3, onClose?: () => void) => {
  message.warning({
    content,
    duration,
    onClick: () => {
      message.destroy();
      onClose?.();
    },
    onClose,
  });
};

export const AlertMessage = {
  error,
  success,
  warning,
};
