import { Some } from "../utils/Some";
import { routes } from "../constants/Routes";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../store/store";
import type { State } from "../store/store";

const getRedirectUrl = (user: NonNullable<State["user"]>) => (user.hasSignature ? routes.FormHistory : routes.Welcome);

const userSelector = (state: State) => state.user;

export function RouteChangeListener() {
  const user = useStore(userSelector);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const urlToRedirectTo = Some(user) && location.pathname === routes.Home ? getRedirectUrl(user) : undefined;

    if (Some(urlToRedirectTo)) {
      navigate(urlToRedirectTo);
    }
  }, [navigate, user, location]);

  return null;
}
