export const routes = {
  AddUser: "/superadmin/add-user",
  CreateForm: "/superadmin/manage-forms/create",
  EditForm: "/superadmin/manage-forms/:formId/edit",
  EditProfile: "/edit-profile",
  EntryForm: "/form/:formEntryId",
  FormEntryPreview: "/form/:formEntryId/preview",
  FormHistory: "/form-history",
  Home: "/",
  LoginFailure: "/login/failure",
  LoginSuccess: "/login/success",
  ManageForms: "/superadmin/manage-forms",
  ManagePrepopulateFields: "/superadmin/manage-prepopulate-fields",
  ManageRoles: "/superadmin/manage-roles/:role",
  Staff: "/staff",
  SuperAdmin: "/superadmin",
  Supervisor: "/supervisor",
  TermsOfService: "/terms-of-service",
  Welcome: "/welcome",
  generateEntryFormUrl: (formEntryId: string) => `/form/${formEntryId}`,
  generateFormEditUrl: (formId: string) => `/superadmin/manage-forms/${formId}/edit`,
  generateFormEntryPreviewUrl: (formEntryId: string) => `/form/${formEntryId}/preview`,
  generateFormRoleUrl: (role: "staff" | "supervisor") => `/superadmin/manage-roles/${role}`,
};
