import { GenericErrorPage } from "./GenericErrorPage";
import { useEffect } from "react";
import { useLogout } from "../../hooks/auth";

export default function Error500Page() {
  const logout = useLogout();

  useEffect(() => {
    const timeout = setTimeout(() => {
      logout();
    }, 10000); // Redirect after 10 seconds

    return () => {
      clearTimeout(timeout);
    };
  }, [logout]);

  return (
    <GenericErrorPage
      paragraphContent={
        <>
          Looks like something isn’t quite right. You will be redirected to&nbsp;
          <a href="/" onClick={logout}>
            {window.location.hostname}
          </a>
          &nbsp;in 10 seconds, or click&nbsp;
          <a href="/" onClick={logout}>
            here
          </a>
          .
        </>
      }
      title="Something went wrong"
    />
  );
}
