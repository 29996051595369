import * as Sentry from "@sentry/browser";
import { App } from "./App";
import { AuthProvider } from "./components/AuthProvider";
import { ConfigProvider } from "antd";
import { SentryErrorBoundary } from "./components/SentryErrorBoundary";
import { theme } from "./constants/Theme";
import React from "react";
import ReactDOM from "react-dom";

const root = document.getElementById("root");

function Index() {
  Sentry.init({
    dsn: "https://433678da8a53433f922415533276569f@o239161.ingest.sentry.io/5847388",
    enabled: process.env.NODE_ENV !== "development",
    environment: process.env.REACT_APP_HOST_ENV,
  });

  return (
    <AuthProvider>
      <SentryErrorBoundary>
        <ConfigProvider theme={theme}>
          <App />
        </ConfigProvider>
      </SentryErrorBoundary>
    </AuthProvider>
  );
}

if (root !== null) {
  ReactDOM.render(<Index />, root);
}
