import { Spin } from "antd";
import { WizardLayout } from "../layouts/WizardLayout";

export function LoadingScreen() {
  return (
    <WizardLayout>
      <Spin size="large" />
    </WizardLayout>
  );
}
