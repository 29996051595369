import { AgentClient } from "../services/AgentClient";
import { AlertMessage } from "../utils/alertMessage";
import { Button, Col, Divider, Image, Layout, Modal, Row, Spin, Tooltip, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Some } from "../utils/Some";
import { routes } from "../constants/Routes";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useLoginService } from "../services/useLoginService";
import { useLogout, useMeritAuth0 } from "../hooks/auth";
import { useNavigate } from "react-router-dom";
import { useStore } from "../store/store";
import jwt_decode from "jwt-decode";
import meritLogo from "../images/merit-logo.png";
import userMeritRelationLogo from "../images/user-merit-relation.png";
import type { MeritUserInfo } from "../types/user";
import type { State } from "../store/store";

const { Content } = Layout;
const { Link, Paragraph, Title } = Typography;

const userSelector = (state: State) => state.user;

export default function TermsOfServicePage() {
  const [showModal, setShowModal] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { accessToken } = useMeritAuth0();
  const navigate = useNavigate();
  const logout = useLogout();
  const { login } = useLoginService();
  const user = useStore(userSelector);

  const orgName = process.env.REACT_APP_ORG_NAME;

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const token = await getAccessTokenSilently();

        if (token.length === 0 || Some(user)) {
          navigate(routes.Home);
        } else {
          setShowModal(true);
        }
      } catch (error) {
        navigate(routes.Home);
      }
    };

    getAccessToken();
  }, [user, navigate, getAccessTokenSilently]);

  const closeModal = () => {
    logout();
  };

  const acceptToSAndLinkAgent = async () => {
    const agentClient = AgentClient(accessToken);
    const { agentID, entityID } = jwt_decode<MeritUserInfo>(accessToken);

    await agentClient.acceptToS(agentID);
    await agentClient.linkEntity(agentID, entityID);
  };

  const onAccept = async () => {
    try {
      setShowModal(false);
      await acceptToSAndLinkAgent();
      login(accessToken);
    } catch (error) {
      AlertMessage.error("There was a problem while accepting terms of service", 3, logout);
    }
  };

  return (
    <Layout className="h100vh p20 pt40">
      <Content>
        {!showModal && (
          <Row className="h100" justify="center" style={{ alignItems: "center" }}>
            <Col style={{ maxWidth: 600 }}>
              <Row justify="center">
                <Col>
                  <Spin size="large" />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        <Modal
          bodyStyle={{
            padding: 12,
          }}
          centered
          closable={false}
          footer={null}
          onCancel={closeModal}
          open={showModal}
        >
          <Row justify="center">
            <Col>
              <Image alt="Merit logo" height="auto" src={meritLogo} width={100} />
            </Col>
          </Row>
          <Row justify="center" style={{ paddingTop: 30 }}>
            <Col>
              <Image alt="User Merit relation logo" height={76} src={userMeritRelationLogo} width={230} />
            </Col>
          </Row>
          <Row style={{ paddingTop: 30 }}>
            <Col>
              <Title className="as-h3">Verified Forms Engine</Title>
            </Col>
          </Row>
          <Row>
            <Typography>
              <Paragraph style={{ fontSize: 15, fontWeight: "400", marginBottom: 0 }}>
                is requesting access to your Merit account on behalf of{" "}
                <span style={{ fontSize: 16, fontWeight: "800" }}>{orgName}</span>
              </Paragraph>
            </Typography>
          </Row>
          <Divider style={{ margin: 12 }} />
          <Row justify="start">
            <Col>
              <Typography>
                <Paragraph style={{ fontWeight: "600", marginBottom: 0 }}>
                  &#9913; View your name and primary email
                </Paragraph>
              </Typography>
            </Col>
          </Row>
          <Divider style={{ margin: 12 }} />
          <Row justify="space-between" style={{ display: "flex", width: "100%" }}>
            <Col>
              <Typography>
                <Paragraph style={{ fontWeight: "600", marginBottom: 0 }}>&#9913; View all of your merits</Paragraph>
              </Typography>
            </Col>
            <Col>
              <Tooltip
                arrow
                color="#fff"
                placement="topLeft"
                title={<span style={{ color: "#000" }}>This includes all accepted and pending merits</span>}
              >
                <InfoCircleOutlined />
              </Tooltip>
            </Col>
          </Row>
          <Divider style={{ margin: 12 }} />

          <Row justify="end" style={{ display: "flex", width: "100%" }}>
            <Col style={{ paddingRight: 10 }}>
              <Button onClick={closeModal} type="default">
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                onClick={() => {
                  onAccept();
                }}
                type="primary"
              >
                Allow
              </Button>
            </Col>
          </Row>

          <>
            <Row style={{ paddingTop: 30 }}>
              <Typography>
                <Paragraph>
                  To learn how Verified Forms Engine will handle your data, please review{" "}
                  <Link
                    href="https://app.merits.com/terms-of-service"
                    style={{ textDecorationLine: "underline" }}
                    target="_blank"
                  >
                    terms of service
                  </Link>{" "}
                  and{" "}
                  <Link
                    href="https://app.merits.com/privacy-policy"
                    style={{ textDecorationLine: "underline" }}
                    target="_blank"
                  >
                    {" "}
                    privacy policy
                  </Link>
                </Paragraph>
              </Typography>
            </Row>
          </>
        </Modal>
      </Content>
    </Layout>
  );
}
