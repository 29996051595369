import { AgentClient } from "../services/AgentClient";
import { AlertMessage } from "../utils/alertMessage";
import { routes } from "../constants/Routes";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useLoginService } from "../services/useLoginService";
import { useNavigate } from "react-router-dom";
import { useStore } from "../store/store";
import jwt_decode from "jwt-decode";
import type { MeritUserInfo } from "../types/user";
import type { State } from "../store/store";
import type { UseMeritAuth0 } from "../types/auth";

const clearUserSelector = (state: State) => state.clearUser;

const minimumLoginAuthScopes =
  "read:container read:agent_entity_link create:agent_entity_linkiss:get-template iss:get-field-kind iss:get-container agents:accept-tos agents:get-agent agents:read-entity-links agents:link-entity-agent";

const useLogin = () => {
  const { getAccessTokenSilently, loginWithPopup } = useAuth0();
  const { login } = useLoginService();
  const navigate = useNavigate();

  const tryGetLinks = async (agentID: string, token: string) => {
    try {
      const agentClient = AgentClient(token);
      const response = await agentClient.getLinks(agentID);

      return { ...response, success: true };
    } catch {
      return { capabilitiesApproved: false, success: false, tosAccepted: false };
    }
  };

  const doPkceFlow = async () => {
    try {
      await loginWithPopup({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE_URL,
          prompt: "login",
          scope: minimumLoginAuthScopes,
        },
      });

      const token = await getAccessTokenSilently();
      const { agentID } = jwt_decode<MeritUserInfo>(token);
      const response = await tryGetLinks(agentID, token);

      if (response.success) {
        const { capabilitiesApproved, tosAccepted } = response;

        if (!capabilitiesApproved || !tosAccepted) {
          navigate(routes.TermsOfService);
        } else {
          login(token);
        }
      } else {
        throw new Error("Failed to get links");
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(`Failed to login: ${String(err)}`);
      const errorMessage =
        err instanceof Error && err.message === "Failed to get links"
          ? "You must finish setting up your account and accepting all terms and conditions before using Merit tooling"
          : "There was a problem logging in";
      AlertMessage.error(errorMessage);
      throw err;
    }
  };

  return doPkceFlow;
};

const useLogout = () => {
  const clearUser = useStore(clearUserSelector);
  const { logout: logoutAuth0 } = useAuth0();

  const logout = () => {
    logoutAuth0({ logoutParams: { returnTo: window.location.origin } });
    clearUser();
  };

  return logout;
};

const useMeritAuth0 = (): UseMeritAuth0 => {
  const { getAccessTokenSilently, isAuthenticated, isLoading, user } = useAuth0();

  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    // eslint-disable-next-line functional/no-let
    let hasCleanedUp = false;
    const getAccessToken = async () => {
      const token = await getAccessTokenSilently();
      if (!hasCleanedUp) {
        setAccessToken(token);
      }
    };

    // Only try to get the accessToken if we are authenticated to avoid console errors
    if (isAuthenticated) {
      getAccessToken();
    }

    return () => {
      hasCleanedUp = true;
    };
  }, [getAccessTokenSilently, isAuthenticated]);

  return {
    accessToken: accessToken ?? "",
    isAuthenticated,
    isLoading,
    // Looks weird, but convert undefined to null for matching API
    user: user === undefined ? null : user,
  };
};

export { useLogin, useLogout, useMeritAuth0 };
