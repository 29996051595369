import axios from "axios";

type GetAgentLinksResponse = {
  readonly capabilitiesApproved: boolean;
  readonly tosAccepted: boolean;
};

export const AgentClient = (accessToken: string) => {
  const baseURL = process.env.REACT_APP_AUTH0_API_AGENT_BASE_URL;
  const httpClient = axios.create({
    baseURL,
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  const getLinks = async (agentID: string) => {
    const url = `/v1/agents/${agentID}/links`;
    const { data } = await httpClient.get<GetAgentLinksResponse>(url);

    return data;
  };

  const acceptToS = async (agentID: string) => {
    const url = `/v1/agents/${agentID}/accept-tos`;
    await httpClient.post(url);
  };

  const linkEntity = async (agentID: string, entityID: string) => {
    const url = `/v1/agents/${agentID}/link/${entityID}`;
    await httpClient.put(url);
  };

  return {
    acceptToS,
    getLinks,
    linkEntity,
  };
};
