import { Form } from "../__generated__/Form";
import { FormEntry } from "../__generated__/FormEntry";
import { Login } from "../__generated__/Login";
import { Merit } from "../__generated__/Merit";
import { Pdf } from "../__generated__/Pdf";
import { Some } from "./Some";
import { User } from "../__generated__/User";
import { useAuth0 } from "@auth0/auth0-react";
import { useLogout } from "../hooks/auth";
import { useMemo } from "react";
import { useStore } from "../store/store";
import axios from "axios";
import type { ApiConfig } from "../__generated__/http-client";
import type { InternalAxiosRequestConfig } from "axios";
import type { State } from "../store/store";

const userSelector = (state: State) => state.user;

export function useApi() {
  const { getAccessTokenSilently } = useAuth0();
  const logout = useLogout();
  const user = useStore(userSelector);

  return useMemo(() => {
    const httpClientConfig: ApiConfig = {
      baseURL: process.env.REACT_APP_BASE_URL,
    };

    const unauthorizedResponseInterceptor = (error: unknown) => {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        logout();

        return;
      }
      throw error;
    };

    const requestInterceptor = async (axiosConfig: InternalAxiosRequestConfig) => {
      const accessToken = await getAccessTokenSilently();

      // eslint-disable-next-line functional/immutable-data, no-param-reassign
      axiosConfig.transformRequest = [
        // @ts-expect-error this is always an []
        ...axios.defaults.transformRequest,
        (data: unknown, headers) => {
          if (Some(accessToken) && accessToken !== "") {
            // eslint-disable-next-line functional/immutable-data, no-param-reassign
            headers.Authorization = `Bearer ${accessToken}`;
          }

          if (Some(user)) {
            // eslint-disable-next-line functional/immutable-data, no-param-reassign
            headers["User-Id"] = user.id;

            // eslint-disable-next-line functional/immutable-data, no-param-reassign
            headers["Allowed-Form-Ids"] = user.forms.map((e) => e.id);
          }

          return data;
        },
      ];

      return axiosConfig;
    };

    const onError = (error: unknown) => Promise.reject(error);

    const formClient = new Form(httpClientConfig);
    const formEntryClient = new FormEntry(httpClientConfig);
    const meritClient = new Merit(httpClientConfig);
    const pdfClient = new Pdf(httpClientConfig);
    const userClient = new User(httpClientConfig);
    const loginClient = new Login(httpClientConfig);

    formClient.instance.interceptors.response.use(undefined, unauthorizedResponseInterceptor);
    formEntryClient.instance.interceptors.response.use(undefined, unauthorizedResponseInterceptor);
    meritClient.instance.interceptors.response.use(undefined, unauthorizedResponseInterceptor);
    pdfClient.instance.interceptors.response.use(undefined, unauthorizedResponseInterceptor);
    userClient.instance.interceptors.response.use(undefined, unauthorizedResponseInterceptor);

    formClient.instance.interceptors.request.use(requestInterceptor, onError);
    formEntryClient.instance.interceptors.request.use(requestInterceptor, onError);
    meritClient.instance.interceptors.request.use(requestInterceptor, onError);
    pdfClient.instance.interceptors.request.use(requestInterceptor, onError);
    userClient.instance.interceptors.request.use(requestInterceptor, onError);

    return {
      formClient,
      formEntryClient,
      loginClient,
      meritClient,
      pdfClient,
      userClient,
    };

    // Removed getAccessTokenSilently and logout from the dependency array because it executes from multiple places.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
