/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

// merit-vfe: Import custom error for 400 status code
import { Error400 } from "../exceptions/Error400";
// merit-vfe

import { LoginPayload, LoginWithMeritPayload } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

// merit-vfe: Possible response structure for generated api clients
type Response<T = void> =
  | {
      readonly success: false;
      readonly message: string;
    }
  | (T extends void
      ? {
          readonly success: true;
        }
      : {
          readonly success: true;
          readonly data: T;
        });
// merit-vfe

export class Login<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name LoginWithMerit
   * @request POST:/api/login/login-with-merit
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  loginWithMerit = (body: LoginWithMeritPayload, params: RequestParams = {}) =>
    this.request<
      {
        url: string;
      },
      any
    >({
      path: `/api/login/login-with-merit`,
      method: "POST",
      body: body,
      type: ContentType.Json,
      format: "json",
      ...params,
    });

  /**
   * No description
   *
   * @name Login
   * @request POST:/api/login/
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  login = async (
    body: LoginPayload,
    params: RequestParams = {},
  ): Promise<
    Response<{
      email: string;
      firstName: string;
      forms: {
        id: string;
        jotformUrl: string;
        name: string;
        operationalPeriod?: {
          meritTemplateIds: string[];
          meritTemplateIdsUsedFor:
            | "Check-in only"
            | "Check-in and Check-out"
            | ("Check-in only" & "Check-in and Check-out");
          title: string;
        };
        orgName: string;
      }[];
      hasSignature: boolean;
      id: string;
      isStaffOfAnyForm: boolean;
      isSuperAdmin: boolean;
      isSupervisorOfAnyForm: boolean;
      lastName: string;
    }>
  > => {
    try {
      const response = await this.request<
        {
          email: string;
          firstName: string;
          forms: {
            id: string;
            jotformUrl: string;
            name: string;
            operationalPeriod?: {
              meritTemplateIds: string[];
              meritTemplateIdsUsedFor:
                | "Check-in only"
                | "Check-in and Check-out"
                | ("Check-in only" & "Check-in and Check-out");
              title: string;
            };
            orgName: string;
          }[];
          hasSignature: boolean;
          id: string;
          isStaffOfAnyForm: boolean;
          isSuperAdmin: boolean;
          isSupervisorOfAnyForm: boolean;
          lastName: string;
        },
        {
          error: string;
        }
      >({
        path: `/api/login/`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
}
