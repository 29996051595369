import { Col, Row, Typography } from "antd";
import { WizardLayout } from "../../layouts/WizardLayout";
import type { ReactNode } from "react";

type Props = {
  readonly title: string;
  readonly paragraphContent: ReactNode;
};

const { Paragraph, Title } = Typography;

export function GenericErrorPage({ paragraphContent, title }: Props) {
  return (
    <WizardLayout>
      <Row className="text-center">
        <Col>
          <Title>{title}</Title>

          <Typography>
            <Paragraph>{paragraphContent}</Paragraph>
          </Typography>
        </Col>
      </Row>
    </WizardLayout>
  );
}
