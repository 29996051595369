import { Navigate, useLocation } from "react-router-dom";
import { None } from "../utils/None";
import { routes } from "../constants/Routes";
import { useStore } from "../store/store";
import type { RouteProps } from "react-router-dom";
import type { State } from "../store/store";

type Props = RouteProps & {
  readonly requireSuperAdmin?: boolean;
  readonly requireSupervisor?: boolean;
  readonly requireStaff?: boolean;
};

const userSelector = (state: State) => state.user;
const hasUserCompletedProfileSelector = (state: State) => state.hasUserCompletedProfile;

export function ProtectedRoute({
  requireStaff = false,
  requireSuperAdmin = false,
  requireSupervisor = false,
  ...routeProps
}: Props) {
  const location = useLocation();
  const user = useStore(userSelector);
  const hasUserCompletedProfile = useStore(hasUserCompletedProfileSelector);

  if (None(user)) {
    return <Navigate to={routes.Home} />;
  }

  if (!hasUserCompletedProfile() && location.pathname !== routes.Welcome) {
    return <Navigate to={routes.Welcome} />;
  }

  if (requireSupervisor && !user.isSupervisorOfAnyForm) {
    return <Navigate to={routes.Home} />;
  }

  if (requireStaff && !user.isStaffOfAnyForm) {
    return <Navigate to={routes.Home} />;
  }

  if (requireSuperAdmin && !user.isSuperAdmin) {
    return <Navigate to={routes.Home} />;
  }

  return <>{routeProps.children}</>;
}
