/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

// merit-vfe: Import custom error for 400 status code
import { Error400 } from "../exceptions/Error400";
// merit-vfe

import { CreateFormEntryPayload, ReassignSupervisorPayload, SaveDraftPayload } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

// merit-vfe: Possible response structure for generated api clients
type Response<T = void> =
  | {
      readonly success: false;
      readonly message: string;
    }
  | (T extends void
      ? {
          readonly success: true;
        }
      : {
          readonly success: true;
          readonly data: T;
        });
// merit-vfe

export class FormEntry<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name GetUserFormHistory
   * @request GET:/api/form-entry/
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  getUserFormHistory = async (
    query: {
      limit: number;
      offset: number;
    },
    params: RequestParams = {},
  ): Promise<
    Response<{
      formEntries: {
        formId: string;
        formName: string;
        id: string;
        operationalPeriodEndDateTime?: string;
        operationalPeriodEndTimeZone?: string;
        operationalPeriodStartDateTime?: string;
        operationalPeriodStartDateTimeFromJotform?: string;
        operationalPeriodStartTimeZone?: string;
        pdfAvailable: boolean;
        status: string;
        submittedDateTime?: string;
        submittingUserName: string;
        supervisorName?: string;
        supervisors: {
          firstName: string;
          id: string;
          lastName: string;
        }[];
        updatedAt: string;
      }[];
      total: number;
    }>
  > => {
    try {
      const response = await this.request<
        {
          formEntries: {
            formId: string;
            formName: string;
            id: string;
            operationalPeriodEndDateTime?: string;
            operationalPeriodEndTimeZone?: string;
            operationalPeriodStartDateTime?: string;
            operationalPeriodStartDateTimeFromJotform?: string;
            operationalPeriodStartTimeZone?: string;
            pdfAvailable: boolean;
            status: string;
            submittedDateTime?: string;
            submittingUserName: string;
            supervisorName?: string;
            supervisors: {
              firstName: string;
              id: string;
              lastName: string;
            }[];
            updatedAt: string;
          }[];
          total: number;
        },
        {
          error: string;
        }
      >({
        path: `/api/form-entry/`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name CreateFormEntry
   * @request POST:/api/form-entry/
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  createFormEntry = async (
    body: CreateFormEntryPayload,
    params: RequestParams = {},
  ): Promise<
    Response<{
      id: string;
    }>
  > => {
    try {
      const response = await this.request<
        {
          id: string;
        },
        {
          error: string;
        }
      >({
        path: `/api/form-entry/`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name GetRejectedFormEntries
   * @request GET:/api/form-entry/rejected
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  getRejectedFormEntries = async (
    query: {
      limit: number;
      offset: number;
    },
    params: RequestParams = {},
  ): Promise<
    Response<{
      formEntries: {
        formId: string;
        formName: string;
        id: string;
        operationalPeriodEndDateTime?: string;
        operationalPeriodEndTimeZone?: string;
        operationalPeriodStartDateTime?: string;
        operationalPeriodStartDateTimeFromJotform?: string;
        operationalPeriodStartTimeZone?: string;
        pdfAvailable: boolean;
        status: string;
        submittedDateTime?: string;
        submittingUserName: string;
        supervisorName?: string;
        supervisors: {
          firstName: string;
          id: string;
          lastName: string;
        }[];
        updatedAt: string;
      }[];
      total: number;
    }>
  > => {
    try {
      const response = await this.request<
        {
          formEntries: {
            formId: string;
            formName: string;
            id: string;
            operationalPeriodEndDateTime?: string;
            operationalPeriodEndTimeZone?: string;
            operationalPeriodStartDateTime?: string;
            operationalPeriodStartDateTimeFromJotform?: string;
            operationalPeriodStartTimeZone?: string;
            pdfAvailable: boolean;
            status: string;
            submittedDateTime?: string;
            submittingUserName: string;
            supervisorName?: string;
            supervisors: {
              firstName: string;
              id: string;
              lastName: string;
            }[];
            updatedAt: string;
          }[];
          total: number;
        },
        {
          error: string;
        }
      >({
        path: `/api/form-entry/rejected`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name GetFormEntry
   * @request GET:/api/form-entry/{id}
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  getFormEntry = async (
    id: string,
    params: RequestParams = {},
  ): Promise<
    Response<{
      encryptedId: string;
      form: {
        id: string;
        name: string;
        timezone: string;
      };
      formEntry: {
        digitallySigned: boolean;
        id: string;
        operationalPeriodEndDateTime?: string;
        operationalPeriodEndTimeZone?: string;
        operationalPeriodStartDateTime?: string;
        operationalPeriodStartTimeZone?: string;
        prepopulatedData: {
          fieldType: string;
          populateFrom?: string;
          uniqueFieldName: string;
          value: any;
        }[];
        rejectionReason?: string;
        status: string;
        submittedDateTime?: string;
        submittingUser: {
          firstName: string;
          lastName: string;
        };
        supervisorId?: string;
      };
      formSupervisors: {
        firstName: string;
        id: string;
        lastName: string;
      }[];
    }>
  > => {
    try {
      const response = await this.request<
        {
          encryptedId: string;
          form: {
            id: string;
            name: string;
            timezone: string;
          };
          formEntry: {
            digitallySigned: boolean;
            id: string;
            operationalPeriodEndDateTime?: string;
            operationalPeriodEndTimeZone?: string;
            operationalPeriodStartDateTime?: string;
            operationalPeriodStartTimeZone?: string;
            prepopulatedData: {
              fieldType: string;
              populateFrom?: string;
              uniqueFieldName: string;
              value: any;
            }[];
            rejectionReason?: string;
            status: string;
            submittedDateTime?: string;
            submittingUser: {
              firstName: string;
              lastName: string;
            };
            supervisorId?: string;
          };
          formSupervisors: {
            firstName: string;
            id: string;
            lastName: string;
          }[];
        },
        {
          error: string;
        }
      >({
        path: `/api/form-entry/${id}`,
        method: "GET",
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name SaveDraft
   * @request PATCH:/api/form-entry/{id}
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  saveDraft = async (id: string, body: SaveDraftPayload, params: RequestParams = {}): Promise<Response<void>> => {
    try {
      const response = await this.request<
        void,
        {
          error: string;
        }
      >({
        path: `/api/form-entry/${id}`,
        method: "PATCH",
        body: body,
        type: ContentType.Json,
        ...params,
      });

      return {
        success: true,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name DeleteFormEntry
   * @request DELETE:/api/form-entry/{id}
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  deleteFormEntry = async (id: string, params: RequestParams = {}): Promise<Response<void>> => {
    try {
      const response = await this.request<
        void,
        {
          error: string;
        }
      >({
        path: `/api/form-entry/${id}`,
        method: "DELETE",
        ...params,
      });

      return {
        success: true,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name CreateFormEntryWebhook
   * @request POST:/api/form-entry/webhook
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  createFormEntryWebhook = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/form-entry/webhook`,
      method: "POST",
      ...params,
    });

  /**
   * No description
   *
   * @name ReassignSupervisor
   * @request POST:/api/form-entry/{id}/reassign
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  reassignSupervisor = async (
    id: string,
    body: ReassignSupervisorPayload,
    params: RequestParams = {},
  ): Promise<Response<void>> => {
    try {
      const response = await this.request<
        void,
        {
          error: string;
        }
      >({
        path: `/api/form-entry/${id}/reassign`,
        method: "POST",
        body: body,
        type: ContentType.Json,
        ...params,
      });

      return {
        success: true,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name GetSupervisorApprovalQueue
   * @request GET:/api/form-entry/supervisor/approval-queue
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  getSupervisorApprovalQueue = async (
    query: {
      limit: number;
      offset: number;
    },
    params: RequestParams = {},
  ): Promise<
    Response<{
      formEntries: {
        formId: string;
        formName: string;
        id: string;
        operationalPeriodEndDateTime?: string;
        operationalPeriodEndTimeZone?: string;
        operationalPeriodStartDateTime?: string;
        operationalPeriodStartDateTimeFromJotform?: string;
        operationalPeriodStartTimeZone?: string;
        pdfAvailable: boolean;
        status: string;
        submittedDateTime?: string;
        submittingUserName: string;
        supervisorName?: string;
        supervisors: {
          firstName: string;
          id: string;
          lastName: string;
        }[];
        updatedAt: string;
      }[];
      total: number;
    }>
  > => {
    try {
      const response = await this.request<
        {
          formEntries: {
            formId: string;
            formName: string;
            id: string;
            operationalPeriodEndDateTime?: string;
            operationalPeriodEndTimeZone?: string;
            operationalPeriodStartDateTime?: string;
            operationalPeriodStartDateTimeFromJotform?: string;
            operationalPeriodStartTimeZone?: string;
            pdfAvailable: boolean;
            status: string;
            submittedDateTime?: string;
            submittingUserName: string;
            supervisorName?: string;
            supervisors: {
              firstName: string;
              id: string;
              lastName: string;
            }[];
            updatedAt: string;
          }[];
          total: number;
        },
        {
          error: string;
        }
      >({
        path: `/api/form-entry/supervisor/approval-queue`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name GetSupervisorFormHistory
   * @request GET:/api/form-entry/supervisor/form-history
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  getSupervisorFormHistory = async (
    query: {
      firstName?: string;
      lastName?: string;
      limit: number;
      offset: number;
    },
    params: RequestParams = {},
  ): Promise<
    Response<{
      formEntries: {
        formId: string;
        formName: string;
        id: string;
        operationalPeriodEndDateTime?: string;
        operationalPeriodEndTimeZone?: string;
        operationalPeriodStartDateTime?: string;
        operationalPeriodStartDateTimeFromJotform?: string;
        operationalPeriodStartTimeZone?: string;
        pdfAvailable: boolean;
        status: string;
        submittedDateTime?: string;
        submittingUserName: string;
        supervisorName?: string;
        supervisors: {
          firstName: string;
          id: string;
          lastName: string;
        }[];
        updatedAt: string;
      }[];
      total: number;
    }>
  > => {
    try {
      const response = await this.request<
        {
          formEntries: {
            formId: string;
            formName: string;
            id: string;
            operationalPeriodEndDateTime?: string;
            operationalPeriodEndTimeZone?: string;
            operationalPeriodStartDateTime?: string;
            operationalPeriodStartDateTimeFromJotform?: string;
            operationalPeriodStartTimeZone?: string;
            pdfAvailable: boolean;
            status: string;
            submittedDateTime?: string;
            submittingUserName: string;
            supervisorName?: string;
            supervisors: {
              firstName: string;
              id: string;
              lastName: string;
            }[];
            updatedAt: string;
          }[];
          total: number;
        },
        {
          error: string;
        }
      >({
        path: `/api/form-entry/supervisor/form-history`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name ApproveFormEntry
   * @request POST:/api/form-entry/supervisor/approve/{id}
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  approveFormEntry = async (id: string, params: RequestParams = {}): Promise<Response<void>> => {
    try {
      const response = await this.request<
        void,
        {
          error: string;
        }
      >({
        path: `/api/form-entry/supervisor/approve/${id}`,
        method: "POST",
        ...params,
      });

      return {
        success: true,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name RejectFormEntry
   * @request POST:/api/form-entry/supervisor/reject/{id}
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  rejectFormEntry = async (
    id: string,
    body: {
      reason: string;
    },
    params: RequestParams = {},
  ): Promise<Response<void>> => {
    try {
      const response = await this.request<
        void,
        {
          error: string;
        }
      >({
        path: `/api/form-entry/supervisor/reject/${id}`,
        method: "POST",
        body: body,
        ...params,
      });

      return {
        success: true,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name GetOrphanedFormEntries
   * @request GET:/api/form-entry/staff/orphaned
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  getOrphanedFormEntries = async (
    query: {
      limit: number;
      offset: number;
    },
    params: RequestParams = {},
  ): Promise<
    Response<{
      formEntries: {
        formId: string;
        formName: string;
        id: string;
        operationalPeriodEndDateTime?: string;
        operationalPeriodEndTimeZone?: string;
        operationalPeriodStartDateTime?: string;
        operationalPeriodStartDateTimeFromJotform?: string;
        operationalPeriodStartTimeZone?: string;
        pdfAvailable: boolean;
        status: string;
        submittedDateTime?: string;
        submittingUserName: string;
        supervisorName?: string;
        supervisors: {
          firstName: string;
          id: string;
          lastName: string;
        }[];
        updatedAt: string;
      }[];
      total: number;
    }>
  > => {
    try {
      const response = await this.request<
        {
          formEntries: {
            formId: string;
            formName: string;
            id: string;
            operationalPeriodEndDateTime?: string;
            operationalPeriodEndTimeZone?: string;
            operationalPeriodStartDateTime?: string;
            operationalPeriodStartDateTimeFromJotform?: string;
            operationalPeriodStartTimeZone?: string;
            pdfAvailable: boolean;
            status: string;
            submittedDateTime?: string;
            submittingUserName: string;
            supervisorName?: string;
            supervisors: {
              firstName: string;
              id: string;
              lastName: string;
            }[];
            updatedAt: string;
          }[];
          total: number;
        },
        {
          error: string;
        }
      >({
        path: `/api/form-entry/staff/orphaned`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name GetStaffFormHistory
   * @request GET:/api/form-entry/staff/form-history
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  getStaffFormHistory = async (
    query: {
      firstName?: string;
      lastName?: string;
      limit: number;
      offset: number;
    },
    params: RequestParams = {},
  ): Promise<
    Response<{
      formEntries: {
        formId: string;
        formName: string;
        id: string;
        operationalPeriodEndDateTime?: string;
        operationalPeriodEndTimeZone?: string;
        operationalPeriodStartDateTime?: string;
        operationalPeriodStartDateTimeFromJotform?: string;
        operationalPeriodStartTimeZone?: string;
        pdfAvailable: boolean;
        status: string;
        submittedDateTime?: string;
        submittingUserName: string;
        supervisorName?: string;
        supervisors: {
          firstName: string;
          id: string;
          lastName: string;
        }[];
        updatedAt: string;
      }[];
      total: number;
    }>
  > => {
    try {
      const response = await this.request<
        {
          formEntries: {
            formId: string;
            formName: string;
            id: string;
            operationalPeriodEndDateTime?: string;
            operationalPeriodEndTimeZone?: string;
            operationalPeriodStartDateTime?: string;
            operationalPeriodStartDateTimeFromJotform?: string;
            operationalPeriodStartTimeZone?: string;
            pdfAvailable: boolean;
            status: string;
            submittedDateTime?: string;
            submittingUserName: string;
            supervisorName?: string;
            supervisors: {
              firstName: string;
              id: string;
              lastName: string;
            }[];
            updatedAt: string;
          }[];
          total: number;
        },
        {
          error: string;
        }
      >({
        path: `/api/form-entry/staff/form-history`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
  /**
   * No description
   *
   * @name UnapproveFormEntry
   * @request POST:/api/form-entry/staff/unapprove/{id}
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  unapproveFormEntry = async (
    id: string,
    body: {
      reason: string;
    },
    params: RequestParams = {},
  ): Promise<Response<void>> => {
    try {
      const response = await this.request<
        void,
        {
          error: string;
        }
      >({
        path: `/api/form-entry/staff/unapprove/${id}`,
        method: "POST",
        body: body,
        ...params,
      });

      return {
        success: true,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
}
