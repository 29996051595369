import * as Sentry from "@sentry/browser";
import { ErrorBoundary } from "react-error-boundary";
import { Some } from "../utils/Some";
import { useStore } from "../store/store";
import Error500Page from "../pages/errors/Error500Page";
import type { ReactNode } from "react";
import type { State } from "../store/store";

type Props = {
  readonly children: ReactNode;
};

const userSelector = (state: State) => state.user;

export function SentryErrorBoundary({ children }: Props) {
  const user = useStore(userSelector);

  const tellSentry = (error: Error) => {
    Sentry.withScope((scope) => {
      if (Some(user)) {
        scope.setUser({
          id: user.id,
        });
      }

      Sentry.captureException(error);
    });
  };

  return (
    <ErrorBoundary FallbackComponent={Error500Page} onError={tellSentry}>
      {children}
    </ErrorBoundary>
  );
}
