import "./less/index.less";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LoadingScreen } from "./components/LoadingScreen";
import { None } from "./utils/None";
import { ProtectedRoute } from "./pages/ProtectedRoute";
import { RouteChangeListener } from "./components/RouteChangeListener";
import { Suspense, lazy, useEffect, useState } from "react";
import { AlertMessage as message } from "./utils/alertMessage";
import { routes } from "./constants/Routes";
import { useApi } from "./utils/useApi";
import { useStore } from "./store/store";
import TermsOfServicePage from "./pages/TermsOfServicePage";
import type { State } from "./store/store";

const AddUser = lazy(() => /* webpackPrefetch: true */ import("./pages/SuperAdmin/AddUserPage"));
const EditForm = lazy(() => /* webpackPrefetch: true */ import("./pages/SuperAdmin/FormPage"));
const EditProfile = lazy(() => /* webpackPrefetch: true */ import("./pages/EditProfilePage"));
const Error404 = lazy(() => /* webpackPrefetch: true */ import("./pages/errors/Error404Page"));
const EntryForm = lazy(() => /* webpackPrefetch: true */ import("./pages/EntryFormPage"));
const FormHistory = lazy(() => /* webpackPrefetch: true */ import("./pages/FormHistoryPage"));
const ManageForms = lazy(() => /* webpackPrefetch: true */ import("./pages/SuperAdmin/ManageFormsPage"));
const Home = lazy(() => /* webpackPrefetch: true */ import("./pages/HomePage"));
const LoginFailure = lazy(() => /* webpackPrefetch: true */ import("./pages/LoginFailurePage"));
const LoginSuccess = lazy(() => /* webpackPrefetch: true */ import("./pages/LoginSuccessPage"));
const ManageRoles = lazy(() => /* webpackPrefetch: true */ import("./pages/SuperAdmin/ManageRolesPage"));
const FormPage = lazy(() => /* webpackPrefetch: true */ import("./pages/SuperAdmin/FormPage"));
const Staff = lazy(() => /* webpackPrefetch: true */ import("./pages/StaffPage"));
const Supervisor = lazy(() => /* webpackPrefetch: true */ import("./pages/SupervisorPage"));
const Welcome = lazy(() => /* webpackPrefetch: true */ import("./pages/WelcomePage"));
const FormEntryPreview = lazy(() => /* webpackPrefetch: true */ import("./pages/FormEntryPreviewPage"));
const ManagePrepopulateFields = lazy(
  () => /* webpackPrefetch: true */ import("./pages/SuperAdmin/ManagePrepopulateFields")
);

const setUserSelector = (state: State) => state.setUser;
const userSelector = (state: State) => state.user;

export const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const setUser = useStore(setUserSelector);
  const user = useStore(userSelector);
  const { userClient } = useApi();

  useEffect(() => {
    const getLoggedInUser = async () => {
      try {
        const response = await userClient.getUser();
        if (response.success) {
          setUser(response.data);
        } else {
          message.error(response.message);
        }
      } catch (err: unknown) {}
      setIsLoading(false);
    };

    if (None(user)) {
      getLoggedInUser();
    } else {
      setIsLoading(false);
    }
  }, [setUser, user, userClient]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <BrowserRouter>
      <RouteChangeListener />
      <Suspense fallback={<LoadingScreen />}>
        <Routes>
          <Route element={<Home />} path={routes.Home} />
          <Route element={<TermsOfServicePage />} path={routes.TermsOfService} />
          <Route element={<LoginFailure />} path={routes.LoginFailure} />
          <Route element={<LoginSuccess />} path={routes.LoginSuccess} />
          <Route
            element={
              <ProtectedRoute>
                <EditProfile />
              </ProtectedRoute>
            }
            path={routes.EditProfile}
          />
          <Route
            element={
              <ProtectedRoute requireSuperAdmin>
                <EditForm />
              </ProtectedRoute>
            }
            path={`${routes.EditForm}`}
          />
          <Route
            element={
              <ProtectedRoute>
                <Welcome />
              </ProtectedRoute>
            }
            path={routes.Welcome}
          />
          <Route
            element={
              <ProtectedRoute>
                <EntryForm />
              </ProtectedRoute>
            }
            path={routes.EntryForm}
          />
          <Route
            element={
              <ProtectedRoute requireStaff>
                <Staff />
              </ProtectedRoute>
            }
            path={routes.Staff}
          />
          <Route
            element={
              <ProtectedRoute requireSupervisor>
                <Supervisor />
              </ProtectedRoute>
            }
            path={routes.Supervisor}
          />
          <Route
            element={
              <ProtectedRoute requireSuperAdmin>
                <AddUser />
              </ProtectedRoute>
            }
            path={routes.AddUser}
          />
          <Route
            element={
              <ProtectedRoute requireSuperAdmin>
                <ManageRoles />
              </ProtectedRoute>
            }
            path={`${routes.ManageRoles}`}
          />
          <Route
            element={
              <ProtectedRoute requireSuperAdmin>
                <FormPage />
              </ProtectedRoute>
            }
            path={routes.CreateForm}
          />
          <Route
            element={
              <ProtectedRoute>
                <FormHistory />
              </ProtectedRoute>
            }
            path={routes.FormHistory}
          />
          <Route
            element={
              <ProtectedRoute requireSuperAdmin>
                <ManageForms />
              </ProtectedRoute>
            }
            path={routes.ManageForms}
          />
          <Route
            element={
              <ProtectedRoute requireSuperAdmin>
                <ManagePrepopulateFields />
              </ProtectedRoute>
            }
            path={routes.ManagePrepopulateFields}
          />
          <Route
            element={
              <ProtectedRoute requireSupervisor>
                <FormEntryPreview />
              </ProtectedRoute>
            }
            path={routes.FormEntryPreview}
          />
          <Route element={<Error404 />} path="*" />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
