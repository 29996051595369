/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

// merit-vfe: Import custom error for 400 status code
import { Error400 } from "../exceptions/Error400";
// merit-vfe

import { HttpClient, RequestParams } from "./http-client";

// merit-vfe: Possible response structure for generated api clients
type Response<T = void> =
  | {
      readonly success: false;
      readonly message: string;
    }
  | (T extends void
      ? {
          readonly success: true;
        }
      : {
          readonly success: true;
          readonly data: T;
        });
// merit-vfe

export class Merit<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name GetMeritTemplateFields
   * @request GET:/api/merit/merit-templates/{meritTemplateId}/fields
   */

  // merit-vfe: Api client body when route handler has a possible 400 staus code
  getMeritTemplateFields = async (
    meritTemplateId: string,
    params: RequestParams = {},
  ): Promise<
    Response<{
      fields: {
        fieldName: string;
        fieldType: string;
        id: string;
      }[];
    }>
  > => {
    try {
      const response = await this.request<
        {
          fields: {
            fieldName: string;
            fieldType: string;
            id: string;
          }[];
        },
        {
          error: string;
        }
      >({
        path: `/api/merit/merit-templates/${meritTemplateId}/fields`,
        method: "GET",
        format: "json",
        ...params,
      });

      return {
        success: true,
        data: response,
      };
    } catch (error: unknown) {
      if (error instanceof Error400) {
        return {
          message: error.message,
          success: false,
        };
      }

      throw error;
    }
  };
  // merit-vfe

  // merit-vfe: Api client body when route handler do not have a possible 400 staus code
}
